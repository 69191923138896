import moment from "moment";
const HelperService = {
  addMinutes(date: any, minutes: number) {
    if (date && minutes) {
      let data = moment(date).add(minutes, "minutes");
      return data;
    } else {
      return new Date();
    }
  },

  addYear(date: any, year: number) {
    if (date && year) {
      let data = moment(date).add(year, "years");
      return data;
    } else {
      return new Date();
    }
  },

  removeYear(date: any, year: number) {
    if (date && year) {
      let data = moment(date).subtract(year, "years");
      return data;
    } else {
      return new Date();
    }
  },

  generateOTP() {
    var digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 4; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
  },

  allowOnlyNumericValue(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 11) {
      e.preventDefault();
      return false;
    }
  },

  // allowOnlyNumericValueCell(e: any) {
  //   var numbers = /^[0-9]$/;
  //   if (!e.key.match(numbers) && e.keyCode != 8) {
  //     e.preventDefault();
  //     return false;
  //   }
  // },

  allowOnlyNumericValueCell(e: any) {
    let keyCode = e.keyCode ? e.keyCode : e.which;


    if (
      (keyCode < 48 || keyCode > 57) &&
      (keyCode != 46 || e.target.innerText.indexOf(".") != -1)
    ) {
      e.preventDefault();
      return false;
    }
    if (
      e.target.innerText != null &&
      e.target.innerText.indexOf(".") > -1 &&
      e.target.innerText.split(".")[1].length > 1 &&
      (e.target.selectionStart == e.target.innerText.length ||
        e.target.selectionStart == e.target.innerText.length - 1 ||
        e.target.selectionStart == e.target.innerText.length - 2)
    ) {
      e.preventDefault();
      return false;
    }
  },

  allowOnlyNumericValueUpto1(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 0) {
      e.preventDefault();
      return false;
    }
  },

  allowOnlyNumericValueUpto2(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 1) {
      e.preventDefault();
      return false;
    }
  },

  allowOnlyNumericValueUpto3(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 2) {
      e.preventDefault();
      return false;
    }
  },

  allowOnlyNumericValueUpto4(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 3) {
      e.preventDefault();
      return false;
    }
  },

  allowOnlyNumericValueupto20(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 19) {
      e.preventDefault();
      return false;
    }
  },

  maxNumber(e: any, maxNumber: number) {
    if (maxNumber && e.currentTarget.value.length > maxNumber) {
      e.preventDefault();
      return false;
    }
  },

  contactFormatter: function (e: any) {
    if (e.currentTarget.value) {
      e.currentTarget.value = this.getFormattedContact(
        e.currentTarget.value ? e.currentTarget.value.replaceAll("-", "") : ""
      );
    }
  },

  allowNewDecimalValue(e: any) {
    let keyCode = e.keyCode ? e.keyCode : e.which;
    if (
      (keyCode < 48 || keyCode > 57) &&
      (keyCode != 46 || e.target.value.indexOf(".") != -1)
    ) {
      e.preventDefault();
      return false;
    }
    if (
      e.target.value != null &&
      e.target.value.indexOf(".") > -1 &&
      e.target.value.split(".")[1].length > 1 &&
      (e.target.selectionStart == e.target.value.length ||
        e.target.selectionStart == e.target.value.length - 1 ||
        e.target.selectionStart == e.target.value.length - 2)
    ) {
      e.preventDefault();
      return false;
    }
  },

  allowFourDecimalValue(e: any) {
    let keyCode = e.keyCode ? e.keyCode : e.which;

    if (
      (keyCode < 48 || keyCode > 57) &&
      (keyCode != 46 || e.target.value.indexOf(".") != -1)
    ) {
      e.preventDefault();
      return false;
    }

    if (
      e.target.value != null &&
      e.target.value.indexOf(".") > -1 &&
      e.target.value.split(".")[1].length > 1 &&
      (e.target.selectionStart == e.target.value.length ||
        e.target.selectionStart == e.target.value.length - 1 ||
        e.target.selectionStart == e.target.value.length - 2)
    ) {
      e.preventDefault();
      return false;
    }
    if (e.target.value > 99.99999) {
      // e.preventDefault();
      e.currentTarget.value = 99.9999;
    }
  },

  checkValue(e: any) {
    if (e.target.value > 99.99999) {
      e.currentTarget.value = 99.99;
    }
  },

  getCommaSeprateValue(e: any) {
    // return e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    if (e < 1000) {
      return e.toString();
    }
    return e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  formateDecimal(value: any) {
    if (value % 1 === 0) {
      return parseFloat(value).toFixed(0);
    }else{
      return parseFloat(value).toFixed(2);
      
      
    }
  },

  formateUptoTwoDecimal(e: any) {
    if (e && e.target) {
      e.currentTarget.value = this.formateDecimal(e.target.value);
    }
  },

  isValidEmail(email: string) {
    return email
      ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
      : true;
  },

  getFormattedContact: function (e: any) {
    if (e) {
      const match = e.toString().replace(/\D+/g, "");
      const part1 = match.length > 2 ? `${match.substring(0, 3)}` : match;
      const part2 = match.length > 3 ? `-${match.substring(3, 6)}` : "";
      const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : "";
      var x = part1 + "" + part2 + "" + part3;
      return x;
    } else {
      return e;
    }
  },

  timeFormatter: function (e: any) {
    if (e.currentTarget.value) {
      e.currentTarget.value = this.getFormattedTime(
        e.currentTarget.value ? e.currentTarget.value.replaceAll(":", "") : ""
      );
    }
  },

  getFormattedTime: function (e: any) {
    if (e) {
      const match = e.replace(/\D+/g, "");
      const part1 = match.length > 1 ? `${match.substring(0, 2)}` : match;
      const part2 = match.length > 2 ? `:${match.substring(2, 4)}` : "";
      var part3 = "";
      if (
        (e.length >= 4 && e.includes("p")) ||
        (e.length >= 4 && e.includes("P"))
      ) {
        var part3 = " PM";
      } else if (
        (e.length >= 4 && e.includes("a")) ||
        (e.length >= 4 && e.includes("A"))
      ) {
        var part3 = " AM";
      }
      var x = part1 + "" + part2 + "" + part3;
      return x;
    } else {
      return e;
    }
  },

  getCurrencyFormatter: function (e: any) {
    if (e || e == 0) {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(e);
    } else {
      return e;
    }
  },

  getCurrencyFormatterWithoutDecimal: function (e: any) {
    if (e || e == 0) {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        // maximunFractionDigits: 2,
      }).format(e);
    } else {
      return e;
    }
  },

  currencyFormat: function (e: any) {
    e.currentTarget.value = this.formatAmount(
      e.currentTarget.value ? e.currentTarget.value.replaceAll(",", "") : ""
    );
  },

  formatAmount: function (e: any) {
    if (e > 0) {
      var numb =
        e.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
        (e.indexOf(".") == -1 ? ".00" : "");
      return numb;
    } else {
      return "";
    }
  },

  getFormatedDateInUs(d: any) {
    return d ? moment.utc(d).format("MM-DD-YY") : "";
  },

  getFormatedDate(d: any) {
    return d ? moment.utc(d).format("MM/DD/YY") : "";
  },

  getFormatedDatebyYYYY(d: any) {
    return d ? moment.utc(d).format("MM/DD/YYYY") : "";
  },

  getFormattedDatebyText(dt: any) {
    return moment.utc(dt).format("DD MMMM YYYY");
  },

  getFormatedDateWithYear(d: any) {
    return d ? moment.utc(d).format("YYYY-MM-DD") : "";
  },

  getFormatedDateandYear(d: any) {
    return d ? moment.utc(d).format("YYYY-M-D") : "";
  },

  getFormatedDateAndTime(dt: any) {
    return moment.utc(dt).format("MM/DD/YY, hh:mm A");
  },

  getFormatedDateAndTimeWithoutUTC(dt: any) {
    return moment(dt).format("MM/DD/YY, hh:mm A");
  },

  getFormatedDateForTimePicker(dt: any) {
    return moment.utc(dt).format("YYYY-MM-DD hh:mm:ss");
  },

  getFormatedDateForSorting(dt: any) {
    if (dt) {
      return moment(dt).format("YYYY-MM-DD");
    }
  },

  getFormatedDateForDetail(dt: any) {
    if (dt) {
      return moment(dt).format("YYYY-M-D");
    }
  },

  getFormateTimeFromTime(t: any) {
    return moment(t, "hh:mm A").format("HH:mm:ss");
  },

  getFormatedTime(d: any) {
    return d ? moment.utc(d).format("hh:mm A") : "";
  },

  getFormatedDateWithoutUTC(dt: any) {
    return moment(dt).format("YYYY-MM-DD hh:mm:ss");
  },

  getFormattedDateInCalender(dt: any) {
    return moment.utc(dt).format("DD-MMMM-YYYY");
  },

  getFormatedTimeWithoutUTC(d: any) {
    return d ? moment(d).format("hh:mm A") : "";
  },

  getFormatedTimeWithSecond(d: any) {
    return d ? moment.utc(d).format("hh:mm:ss") : "";
  },

  getFormatedDays(dt: any) {
    return moment.utc(dt).format("dddd");
  },

  removeHtml(data: string) {
    if (data) {
      return data
        .replace(/<\/?[^>]+(>|$)/g, " ")
        .replace(/\&nbsp;/g, "")
        .replaceAll("&amp;", "&")
        .replaceAll("&quot;", '"')
        .replaceAll("&#39;", "'")
        .replaceAll("&lt;", "<")
        .replaceAll("&gt;", ">")
        .trim();
    }

    return "";
  },

  isEmptyObject(data: object) {
    return Object.keys(data).length === 0;
  },

  getString(data: string, size: number) {
    if (data.length > size - 3) {
      return data.substring(0, size - 3) + "...";
    }

    return data;
  },

  getSubstring(string: string, char1: string, char2: string) {
    return string.slice(
      string.indexOf(char1) + char1.length,
      string.lastIndexOf(char2)
    );
  },

  validCreditCard(number: any) {
    var data = number.replaceAll("-", "");
    var isValid = "";
    var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    var amexpRegEx = /^(?:3[47][0-9]{13})$/;
    var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    if (visaRegEx.test(data)) {
      isValid = "VISA";
    } else if (mastercardRegEx.test(data)) {
      isValid = "MASTERCARD";
    } else if (amexpRegEx.test(data)) {
      isValid = "AMERICANEXPRESS";
    } else if (discovRegEx.test(data)) {
      isValid = "DISCOVERCARD";
    }
    return isValid;
  },

  formatCreditCard: function (e: any) {
    if (e.currentTarget.value) {
      e.currentTarget.value = this.getformatCreditCard(
        e.currentTarget.value ? e.currentTarget.value.replaceAll("-", "") : ""
      );
    }
  },

  getCompanyIcon: function (e: any) {
    if (e) {
      let logo = e.toUpperCase().split(" ");
      if (logo.length >= 2) {
        return `${logo[0].charAt(0)}${logo[1].charAt(0)}`;
      } else if (logo.length == 1) {
        return `${logo[0].charAt(0)}${logo[0].charAt(1)}}`;
      } else {
        return "";
      }
    }
  },

  getTimes(startTime: any, endTime: any, interval: number) {
    let allTimes = [];
    while (startTime < endTime) {
      allTimes.push(startTime.format("YYYY-MM-DD HH:mm:ss"));
      startTime.add(interval, "minutes");
    }

    return allTimes;
  },

  getformatCreditCard(e: any) {
    if (e) {
      const match = e.replace(/\D+/g, "");
      const part1 = match.length > 3 ? `${match.substring(0, 4)}` : match;
      const part2 = match.length > 4 ? `-${match.substring(4, 8)}` : "";
      const part3 = match.length > 8 ? `-${match.substring(8, 12)}` : "";
      const part4 = match.length > 12 ? `-${match.substring(12, 16)}` : "";
      var x = part1 + "" + part2 + "" + part3 + "" + part4;
      return x;
    } else {
      return e;
    }
  },

  getShadeColor(color: string, percent: any) {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" +
            Math.min(255, Math.max(0, parseInt(color, 16) + percent)).toString(
              16
            )
          ).substr(-2)
        )
    );
  },

  getFromThen(value: any) {
    if (value) {
      var text = value.split(" ");
      var str = "";
      var isAdd = false;
      if (text.length > 0) {
        for (var i in text) {
          if (text[i] == "then") {
            isAdd = true;
          }
          if (isAdd) {
            str += `${text[i]} `;
          }
        }
      }
      return str;
    }
  },

  filter(template: string, values: any) {
    if (!values || !values.length || !template) {
      return template;
    }
    return this.toFormattedString(false, template, values);
  },

  toFormattedString(useLocale: boolean, format: any, values: any) {
    var result = "";

    for (var i = 0; ; ) {
      // Find the next opening or closing brace
      var open = format.indexOf("{", i);
      var close = format.indexOf("}", i);
      if (open < 0 && close < 0) {
        // Not found: copy the end of the string and break
        result += format.slice(i);
        break;
      }
      if (close > 0 && (close < open || open < 0)) {
        if (format.charAt(close + 1) !== "}") {
          throw new Error("format stringFormatBraceMismatch");
        }

        result += format.slice(i, close + 1);
        i = close + 2;
        continue;
      }

      // Copy the string before the brace
      result += format.slice(i, open);
      i = open + 1;

      // Check for double braces (which display as one and are not arguments)
      if (format.charAt(i) === "{") {
        result += "{";
        i++;
        continue;
      }

      if (close < 0) throw new Error("format stringFormatBraceMismatch");

      // Find the closing brace

      // Get the string between the braces, and split it around the ':' (if any)
      var brace = format.substring(i, close);
      var colonIndex = brace.indexOf(":");
      var argNumber = parseInt(
        colonIndex < 0 ? brace : brace.substring(0, colonIndex),
        10
      );

      if (isNaN(argNumber)) throw new Error("format stringFormatInvalid");

      var argFormat = colonIndex < 0 ? "" : brace.substring(colonIndex + 1);

      var arg = values[argNumber];
      if (typeof arg === "undefined" || arg === null) {
        arg = "";
      }

      // If it has a toFormattedString method, call it.  Otherwise, call toString()
      if (arg.toFormattedString) {
        result += arg.toFormattedString(argFormat);
      } else if (useLocale && arg.localeFormat) {
        result += arg.localeFormat(argFormat);
      } else if (arg.format) {
        result += arg.format(argFormat);
      } else result += arg.toString();

      i = close + 1;
    }

    return result;
  },

  exicuteFormula(data: any, initial: boolean) {
    var count = 0;

    do {
      count++;
      data.map((sheet: any, index: number) => {
        sheet.data.map((row: any, ix: number) => {
          Object.entries(row.columns).map(async (item: any) => {
            if (
              item.length > 1 &&
              item[1].formula &&
              (item[1].formula + "").startsWith("=")
            ) {
              if (index == 0 && item[0] == "G" && ix == 52) {
              }
              let formula = "";
              if (
                item[1].formula.indexOf("IF") != -1 ||
                item[1].formula.indexOf("SPHINX") != -1
              ) {
                formula = window.excelFormulaUtilities.formula2JavaScript(
                  item[1].formula
                );
                formula = formula.replaceAll("Math.min", "MIN");
                formula = formula.replaceAll("Math.max", "MAX");
                formula = formula.replaceAll("?:", "?0:");
              } else {
                formula = item[1].formula.substring(1, item[1].formula.length);
              }

              if (formula.startsWith("-")) {
                formula = formula.substring(1, formula.length);
                item[1].isMinus = true;
              }

              // if (formula.startsWith("(") && formula.endsWith(")")) {
              //   formula = formula.substring(1, formula.length - 1);
              // }

              let temp = formula.split(/[\/\+<>&=:?,%!())\-\*]/);

              for (let r of temp) {
                var obj = r;
                var i = index;
                if (r.indexOf("SPHINX") != -1) {
                  var a = r.split("SPHINX");
                  i = Number(a[0].substring(a[0].length - 1, a[0].length));
                  obj = a[1];
                }

                if (obj.match(/^[a-zA-Z][0-9.,$;]+$/)) {
                  var col = obj[0];
                  var ro = Number(obj.replace(col, "")) - 1;
                  if (
                    data[i].data[ro]?.columns[col]?.value ||
                    data[i].data[ro]?.columns[col]?.value == 0 ||
                    data[i].data[ro]?.columns[col]?.value == 0o0
                  ) {
                    // for franmce issue
                    var output = data[i].data[ro].columns[col].value;

                    if (
                      typeof output === "string" &&
                      (output == "00" || output == "00\n" || output == "00\t")
                    ) {
                      output = output.replaceAll("00", "0");
                    }
                    //#region assing 0
                    if (!output || (output && (output+'').trim() == '')) {
                      output = 0;
                    }

                    output = output + "";
                    if (output.startsWith("=")) {
                      output = output.substring(1, output.length);
                    }

                    if (output == "and above" || output == "Above") {
                      output = "10000000000";
                    }

                    output = output.replace("%", "/100");

                    ////old
                    // if (!output.match(/[a-zA-Z][0-9.,$;]/) || output == "0") {
                    //   if (output.match(/^[a-zA-Z0-9()%/ -]+$/)) {
                    //     if (output.match(/^[+-]?([0-9]*[.])?[0-9]+/) || output.match(/^[+-]?([0-9]*[.])?[0-9]+$/)) {
                    //       try {
                    //         const reg = /^[+-]?([0-9]*[.])?[0-9]+^/
                    //         if (output.match(reg)) {
                    //           output = "'" + output + "'";
                    //         } else {
                    //           output = eval(output);
                    //         }
                    //       } catch {
                    //       }
                    //     }
                    //     else {
                    //       output = "'" + output + "'";
                    //     }
                    //   }

                    //   output = output + "";
                    //   if (r.indexOf("SPHINX") != -1) {
                    //     if (output.match(/[+-]?([0-9]*[.])?[0-9]+/)) {
                    //       if (item[1].isMinus) {
                    //         output = -output;
                    //       }
                    //       formula = formula.replace(r, output);
                    //     } else {
                    //       formula = formula.replace(r, output);
                    //     }
                    //   } else {
                    //     formula = formula.replace(obj, output);
                    //   }
                    // }

                    ///new for japanj
                    if (!output.match(/[a-zA-Z][0-9.,$;]/) || output == "0") {
                      if (output.match(/^[a-zA-Z0-9()%/ -]+$/)) {
                        // Add a condition to handle "years"
                        if (output.match(/\d+\s+years/)) {
                          output = "'" + output + "'";
                        } else if (
                          output.match(/^[+-]?([0-9]*[.])?[0-9]+/) ||
                          output.match(/^[+-]?([0-9]*[.])?[0-9]+$/)
                        ) {
                          try {
                            const reg = /^[+-]?([0-9]*[.])?[0-9]+^/;
                            if (output.match(reg)) {
                              output = "'" + output + "'";
                            } else {
                              output = eval(output);
                            }
                          } catch {
                          }
                        } else {
                          output = "'" + output + "'";
                        }
                      }

                      output = output + "";
                      if (r.indexOf("SPHINX") != -1) {
                        if (output.match(/[+-]?([0-9]*[.])?[0-9]+/)) {
                          if (item[1].isMinus) {
                            output = -output;
                          }
                          formula = formula.replace(r, output);
                        } else {
                          formula = formula.replace(r, output);
                        }
                      } else {
                        formula = formula.replace(obj, output);
                      }
                    }

                    //
                  }
                }
              }

              if (formula.match(/[a-zA-Z][0-9.,$;]/)) {
                item[1].value = "=" + formula;
              } else {
                if (formula.indexOf("ROUNDUP") != -1) {
                  formula = this.roundUp(formula);
                }

                formula = formula.replaceAll("%", "/100");

                if (formula.indexOf("MIN") != -1) {
                  let methods: string[] = [];
                  methods = this.extractMinMax(formula);
                  for (let i in methods) {
                    formula = formula.replace(
                      methods[i],
                      this.evaluateMinMax(methods[i]) + ""
                    );
                  }
                }

                if (formula.indexOf("MAX") != -1) {
                  let methods: string[] = [];
                  methods = this.extractMinMax(formula);
                  for (let i in methods) {
                    formula = formula.replace(
                      methods[i],
                      this.evaluateMinMax(methods[i]) + ""
                    );
                  }
                }

                if (formula.indexOf("ROUNDDOWN") != -1) {
                  formula = this.rountDown(formula);
                }

                if (formula.indexOf("MROUND") != -1) {
                  formula = this.mround(formula);
                }

                if (formula.indexOf("ROUND") != -1) {
                  formula = this.round(formula);
                }

                if (formula.indexOf("UPPER") != -1) {
                  formula = this.upperLeft("(" + formula + ")");
                }

                formula = formula.replaceAll("--", "+");
                formula = formula.replaceAll("-+", "-");
                formula = formula.replaceAll("+-", "-");

                if (!formula.match(/[\/\+<>&=:?())\-\*]/)) {
                  item[1].value = formula;
                } else {
                  try {
                    if (formula.length > 50) {
                      formula = "(" + formula + ")";
                    }
                    // canada sheet
                   
                    item[1].value = eval(formula);
                  } catch {
                    item[1].value = formula;
                  }
                }
              }
            } else if (item.length > 1 && item[1].valueRange) {
              let input =
                data[index].data[item[1].valueRow].columns[item[1].valueCol]
                  .value;
              if (input) {
                for (let i = item[1].valueStart; i <= item[1].valueEnd; i++) {
                  item[1].valueRange.map((res: any) => {
                    if (
                      data[index].data[i].columns[res] &&
                      data[index].data[i].columns[res].value &&
                      data[index].data[i].columns[res].value == input
                    ) {
                      item[1].value = eval(
                        data[index].data[i].columns[
                          item[1].valueRange[item[1].valueIndex]
                        ].value
                      );
                    }
                  });
                }
              }
            }
          });
        });

        if (count == 1 && index == 0 && initial) {
          sheet.drop_down.map((dropdown: any) => {
            const res1 = dropdown.dropdown;

            const id = res1.list_cells.split(":")[0];
            let selectDropdown: any = document.getElementById(id);
            if (selectDropdown && res1.list_values && res1.list_values.length) {
              res1.list_values.map((resp: any) => {
                let node = document.createElement("option");
                node.textContent = resp;
                node.value = resp;
                selectDropdown.appendChild(node);
              });
            }
          });
        }
      });
    } while (count < 5);

    return this.assignValue(data);
  },

  assignValue(data: any) {
    data[0].data.map((item: any, index: number) => {
      Object.entries(item.columns).map(async (res: any, ix: number) => {
        let updateCol: any = document.getElementById(res[0] + item.row_number);
        if (updateCol && updateCol.tagName === "SELECT") {
          updateCol.value = item.columns[res[0]].value
            ? item.columns[res[0]].value.replaceAll("'", "")
            : item.columns[res[0]].value || "";
        } else if (
          item.columns[res[0]].value &&
          (item.columns[res[0]].value + "").match(/^[-0-9]+.[0-9]+$/)
        ) {


          updateCol &&
            (updateCol.innerText =
              HelperService.formateDecimal(item.columns[res[0]].value) || "");
        } else {
          if (/^\d+$/.test(item.columns[res[0]].value)) {
            return (updateCol && (updateCol.innerText = Number(item.columns[res[0]].value)));
          }
          updateCol && (updateCol.innerText = item.columns[res[0]].value || "");
        }
      });
    });

    return data;
  },

  rountDown(z: string): string {
    const a = /^ROUNDDOWN\(([-\d.]+),\s*(-?\d+)\)$/;
    const b = z.match(a);
    if (!b) {

      return "";
    }
    const [, numStr, digStr] = b;
    const num = parseFloat(numStr);
    const num1 = parseInt(digStr, 10);
    const deno = Math.pow(10, num1);
    return Math.floor(num / deno) * deno + "";
  },

  roundUp(formul: string): string {
    const complexRegex =
      /^ROUNDUP\(IF\(AND\(([-\d.]+)>([-\d.]+),([-\d.]+)<=([-\d.]+)\),([-\d.]+)\*([-\d.]+)\+([-\d.]+)\*\(([-\d.]+)-([-\d.]+)\*([-\d.]+)\),([-\d.]+)\),\s*(-?\d+)\)$/;
    const complexRegexAlt =
      /^ROUNDUP\(IF\(([-\d.%]+)([<>]=?)([-\d.%*]+),([-\d.%*]+),([-\d.%*]+)\),\s*(-?\d+)\)$/;
    const ternaryRegex =
      /^ROUNDUP\(\(?([-.\d%]+)([<>]=?)([-.\d%*]+)\??([-\d.%*]+):([-\d.%*]+)\)?,\s*(-?\d+)\)$/;
    const simpleRegex = /^ROUNDUP\(([-\d.]+),\s*(-?\d+(?:\.\d+)?)\)$/;
    const arithmeticRegex = /^ROUNDUP\(([^,]+),\s*(-?\d+(?:\.\d+)?)\)^/;

    const parseValue = (str: string): number => {
      if (str.includes("%")) {
        return parseFloat(str.replace("%", "")) / 100;
      }
      return parseFloat(str);
    };

    const evaluateExpression = (expr: string): number => {
      const parts = expr.split("*").map((part) => part.trim());
      if (parts.length === 2) {
        return parseValue(parts[0]) * parseValue(parts[1]);
      }
      return parseValue(expr);
    };

    let mat = formul.match(complexRegex);
    if (mat) {
      const [
        ,
        condition1LeftStr,
        condition1RightStr,
        condition2LeftStr,
        condition2RightStr,
        multiplier1Str,
        multiplier2Str,
        multiplier3Str,
        baseStr,
        factorStr,
        factorMultiplierStr,
        elseValueStr,
        digitsStr,
      ] = mat;

      const condition1Left = parseFloat(condition1LeftStr);
      const condition1Right = parseFloat(condition1RightStr);
      const condition2Left = parseFloat(condition2LeftStr);
      const condition2Right = parseFloat(condition2RightStr);
      const multiplier1 = parseFloat(multiplier1Str);
      const multiplier2 = parseFloat(multiplier2Str);
      const multiplier3 = parseFloat(multiplier3Str);
      const base = parseFloat(baseStr);
      const factor = parseFloat(factorStr);
      const factorMultiplier = parseFloat(factorMultiplierStr);
      const elseValue = parseFloat(elseValueStr);
      const val = parseInt(digitsStr, 10);

      let b = elseValue;
      if (
        condition1Left > condition1Right &&
        condition2Left <= condition2Right
      ) {
        b =
          multiplier1 * multiplier2 +
          multiplier3 * (base - factor * factorMultiplier);
      }

      return Math.ceil(b * Math.pow(10, val)) / Math.pow(10, val) + "";
    }

    mat = formul.match(complexRegexAlt);
    if (mat) {
      const [
        ,
        leftStr,
        operator,
        rightStr,
        thenValueStr,
        elseValueStr,
        digitsStr,
      ] = mat;

      const left = parseValue(leftStr);
      const right = evaluateExpression(rightStr);
      const thenValue = evaluateExpression(thenValueStr);
      const elseValue = evaluateExpression(elseValueStr);
      const val = parseInt(digitsStr, 10);

      let b = elseValue;
      if (
        (operator === ">" && left > right) ||
        (operator === ">=" && left >= right) ||
        (operator === "<" && left < right) ||
        (operator === "<=" && left <= right)
      ) {
        b = thenValue;
      }

      return Math.ceil(b * Math.pow(10, val)) / Math.pow(10, val) + "";
    }

    mat = formul.match(ternaryRegex);
    if (mat) {
      const [
        ,
        leftStr,
        operator,
        rightStr,
        thenValueStr,
        elseValueStr,
        digitsStr,
      ] = mat;

      const left = parseValue(leftStr);
      const right = evaluateExpression(rightStr);
      const thenValue = evaluateExpression(thenValueStr);
      const elseValue = evaluateExpression(elseValueStr);
      const val = parseInt(digitsStr, 10);

      let b = elseValue;
      if (
        (operator === ">" && left > right) ||
        (operator === ">=" && left >= right) ||
        (operator === "<" && left < right) ||
        (operator === "<=" && left <= right)
      ) {
        b = thenValue;
      }

      return Math.ceil(b * Math.pow(10, val)) / Math.pow(10, val) + "";
    }

    mat = formul.match(simpleRegex);
    if (mat) {
      const [, numberStr, digitsStr] = mat;
      const number = parseFloat(numberStr);
      const val = parseInt(digitsStr, 10);

      const c = Math.pow(10, val);
      return Math.ceil(number / c) * c + "";
    }

    mat = formul.match(arithmeticRegex);
    if (mat) {
      const [, expressionStr, digitsStr] = mat;
      const number = evaluateExpression(expressionStr);
      const val = parseFloat(digitsStr);

      const c = Math.pow(10, val);
      return Math.ceil(number * c) / c + "";
    }

    return "0";
  },

  round(formul: string): string {
    const roundRegex =
      /^ROUND\((\(([-\d.]+)([<>]=?)([-\d.]+)\?(.*):(.*)\)|IF\(([-\d.]+)([<>]=?)([-\d.]+),(.*),(.*)\)),\s*(-?\d+)\)$/;
    const roundMathRegex = /^ROUND\(([^,]+),\s*(-?\d+)\)$/;

    const parseValue = (str: string): number => {
      return parseFloat(str);
    };

    const evaluateExpression = (expr: string): number => {
      try {
        return Function(`"use strict"; return (${expr})`)();
      } catch (e) {
        return NaN;
      }
    };

    const mat = formul.match(roundRegex);
    if (mat) {
      const [
        ,
        ,
        leftStr1,
        operator1,
        rightStr1,
        thenValueStr1,
        elseValueStr1,
        leftStr2,
        operator2,
        rightStr2,
        thenValueStr2,
        elseValueStr2,
        digitsStr,
      ] = mat;

      let left: number, right: number, thenValue: number, elseValue: number;
      if (leftStr1 !== undefined) {
        left = parseValue(leftStr1);
        right = parseValue(rightStr1);
        thenValue = evaluateExpression(thenValueStr1);
        elseValue = evaluateExpression(elseValueStr1);
      } else {
        left = parseValue(leftStr2);
        right = parseValue(rightStr2);
        thenValue = evaluateExpression(thenValueStr2);
        elseValue = evaluateExpression(elseValueStr2);
      }

      const val = parseInt(digitsStr, 10);

      let result = elseValue;
      if (
        (operator1 === "<=" && left <= right) ||
        (operator1 === ">" && left > right) ||
        (operator2 === "<=" && left <= right) ||
        (operator2 === ">" && left > right)
      ) {
        result = thenValue;
      }

      const factor = Math.pow(10, val);
      return Math.round(result * factor) / factor + "";
    }

    const mathMat = formul.match(roundMathRegex);
    if (mathMat) {
      const [, expression, digitsStr] = mathMat;
      const val = parseInt(digitsStr, 10);
      const result = evaluateExpression(expression);
      const factor = Math.pow(10, val);
      return Math.round(result * factor) / factor + "";
    }

    return NaN + "";
  },

  upperLeft(y: string): string {
    const regex =
      /^\(*UPPER\(LEFT\('([^']+)'\s*,\s*(\d+)\)\s*\)==="([A-Z])"\?(\d+):(\d+)\)*$/;
    const b = y.match(regex);

    if (!b) {

      throw new Error("Invalid formula format");
    }

    const first = b[1];
    const second = parseInt(b[2], 10);
    const third = b[3];
    const trueResult = parseInt(b[4], 10);
    const falseResult = parseInt(b[5], 10);

    const res = first.substring(0, second).toUpperCase();

    try {
      return (res === third ? trueResult : falseResult) + "";
    } catch {
      return "";
    }
    // return (res === third ? trueResult : falseResult) + "";
  },

  mround(formula: string) {
    if (formula.startsWith("MROUND")) {
      return this.getMround(formula);
    } else {
      formula.startsWith("(") &&
        (formula = formula.substring(1, formula.length - 1));
      var temp = formula.split(":");
      return temp[0] + ":" + this.getMround(temp[1]);
    }
  },

  getMround(formula: string) {
    formula = formula.substring(7, formula.length - 1);
    var temp = formula.split(",");
    return (
      Math.round(Math.round(eval(temp[0])) / eval(temp[1])) * eval(temp[1])
    ).toString();
  },

  extractMinMax(input: string): string[] {
    const methods: string[] = [];
    const regex = /(MIN|MAX)\(/gi;

    let match;
    while ((match = regex.exec(input)) !== null) {
      const methodType = match[1];
      let startIndex = match.index + methodType.length + 1;
      let openParens = 1;

      for (let i = startIndex; i < input.length; i++) {
        if (input[i] === "(") openParens++;
        if (input[i] === ")") openParens--;

        if (openParens === 0) {
          const params = input.substring(startIndex, i);
          methods.push(`${methodType}(${params})`);
          break;
        }
      }
    }

    return methods;
  },
  evaluateMinMax(method: string): number {
    const minRegex = /^MIN\((.*)\)$/i;
    const maxRegex = /^MAX\((.*)\)$/i;

    let params: string[];
    if (minRegex.test(method)) {
      params = method
        .match(minRegex)![1]
        .split(",")
        .map((param) => param.trim());
      return Math.min(...params.map((param) => this.evaluateExpression(param)));
    } else if (maxRegex.test(method)) {
      params = method
        .match(maxRegex)![1]
        .split(",")
        .map((param) => param.trim());
      return Math.max(...params.map((param) => this.evaluateExpression(param)));
    }

    throw method;
  },

  evaluateExpression(expression: string): number {
    // const percentageHandledExpression = expression.replace(
    //   /(\d+(\.\d+)?)%/g,
    //   (_, p1) => `(${parseFloat(p1) / 100})`
    // ).replace(
    //   /(\d+\/\d+)/g,
    //   (_, p1) => `(${p1})`
    // );

    // try {
    //   return new Function(`"use strict"; return (${percentageHandledExpression})`)() ;
    // } catch (error) {
    //   return NaN;
    // }

    try {
      return eval(expression);
    } catch {
    }

    return 0;
  },

  // const string1 = "126000>70000?MAX(660000/1000-(126000-70000)*50/100,500000/1000):0";
  // const string2 = "=IF(126000>70000,MAX(660000/1000-(126000-70000)*50%,500000/1000),0)";
  // const string3 = "=MAX(660000/1000-(126000-70000)*50%,500000/1000)";
  // const string4 = "=MAX(500,900)";
  // const stringMIN1 = "=IF(126000>70000,MIN(660000/1000-(126000-70000)*50%,500000/1000),0)";
  // const stringMIN2 = "126000>70000?MIN(660000/1000-(126000-70000)*50/100,500000/1000):0";

  // const methods1 = extractMinMax(string1);
  // const methods2 = extractMinMax(string2);
  // const methods3 = extractMinMax(string3);
  // const methods4 = extractMinMax(string4);
  // const methodsMIN1 = extractMinMax(stringMIN1);
  // const methodsMIN2 = extractMinMax(stringMIN2);
};

export default HelperService;
